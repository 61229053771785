import { DropdownMenuOptionItem } from '@air/primitive-dropdown-menu';

import { MenuOptions, MenuOptionsName } from '~/constants/MenuOptions';

/**
 * Takes MenuOptionName and prepares it for use within our DropdownMenu view.
 */
export const getDropdownMenuOption = (
  name: MenuOptionsName,
): Pick<DropdownMenuOptionItem, 'id' | 'description' | 'prefix' | 'label'> => {
  const { description, id, Icon, label } = MenuOptions[name] || {};

  return {
    id,
    label,
    description,
    prefix: Icon && <Icon className="size-4" />,
  };
};
