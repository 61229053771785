import { Caution as CautionIcon } from '@air/next-icons';

import { DELETE_ASSETS_WARNING } from '~/constants/testIDs';

export const DeleteAssetsWarning = () => {
  return (
    <div className="flex items-center justify-start" data-testid={DELETE_ASSETS_WARNING}>
      <CautionIcon className="size-9 text-flamingo-300" />
      <div className="ml-2 text-14 text-flamingo-700">
        Deleted assets <span className="text-14 font-semibold text-inherit">CANNOT BE RECOVERED</span>
      </div>
    </div>
  );
};
