import { Modifier } from 'react-popper';

// Source: https://www.npmjs.com/package/popper-max-size-modifier
export const applyMaxSize: Modifier<'applyMaxSize'> = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'afterWrite',
  requires: ['maxSize'],
  fn({ state }) {
    // The `maxSize` modifier provides this data
    const { width, height } = state.modifiersData.maxSize;

    state.styles.popper = {
      ...state.styles.popper,
      maxWidth: `${width}px`,
      maxHeight: `${height}px`,
    };
  },
};

// Source: https://codesandbox.io/s/bitter-sky-pe3z9?file=/src/index.js
export const sameWidth: Modifier<'sameWidth'> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn({ state }) {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect({ state }) {
    state.elements.popper.style.width = `${(state.elements.reference as HTMLDivElement).offsetWidth}px`;
  },
};
