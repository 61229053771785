import { CompletedDraggingParams } from '@air/analytics';
import { Board, Clip } from '@air/api/types';

import { GalleryItemType } from '~/components/Gallery/types';

export type DropType = 'move' | 'rearrange' | 'move-to-library' | 'rejected';

export interface DropData<T = unknown> {
  type: DropType;
  location: DropLocation;
  data?: T;
}

export enum DragType {
  boardNavFavorites = 'boardNavFavorites', // board dragged from nav bar - favorites section
  boardNavWorkspace = 'boardNavWorkspace', // board dragged from nav bar - workspace boards section
  boardNavLibrary = 'boardNavLibrary', // board dragged from nav bar - library boards section
  boardCard = 'board', // board dragged from gallery and table view
  asset = 'asset',
  file = 'file',
  workspace = 'workspace',
  version = 'version', //versions reordered in asset modal tab
}

export const boardDragTypes = [
  DragType.boardNavWorkspace,
  DragType.boardNavFavorites,
  DragType.boardCard,
  DragType.boardNavLibrary,
];

export const isBoardDragType = (type: DragType) => boardDragTypes.includes(type);

export type RearrangableItemType = GetAssetDragItem | GetBoardDragItem;

export interface DraggingItems<FirstItem extends Board | Clip> {
  /** This is the number that shows next to the thumbnail */
  count: number;

  /** First dragged item (we show its thumbnail and title) */
  firstItem: FirstItem;

  /** type of passed item */
  type: DragType;

  /** true if count > 2 and user drags items of different types, e.g. assets and boards */
  mixedTypes: boolean;
}

interface GetItem<Type extends Board | Clip> extends DraggingItems<Type> {
  id: string;
  index?: number;
}

export interface GetAssetDragItem extends Required<GetItem<Clip>> {
  type: DragType.asset | DragType.file;
  assetId?: Clip['assetId'];
}

export interface GetBoardDragItem extends Required<GetItem<Board>>, Pick<Board, 'parentId'> {
  type: DragType.boardCard | DragType.boardNavWorkspace | DragType.boardNavFavorites | DragType.boardNavLibrary;
}

export interface DragWorkspaceItem {
  type: DragType.workspace;
  id: string;
  index: number;
  image: string;
}

export interface DragVersionItem extends Omit<Clip, 'type'> {
  type: DragType.version;
}

export type DropLocation = CompletedDraggingParams['dropLocation']['type'];

export type DragContainers = 'workspace' | 'navBoard' | 'versions' | 'other';

export const DragContainerIds: { [key in DragContainers]: string } = {
  workspace: 'workspaces-nav-container',
  navBoard: 'fat-nav-container',
  versions: 'asset-modal-versions-tab',
  other: 'scrollContainer',
};

export interface RearrangeItemBasic {
  id: string;
  index: number;
}

export interface ItemToRearrange extends RearrangeItemBasic {
  type: DragType;
}

export type RearrangeDirection = 'before' | 'after';

export interface RearrangableItem {
  direction: RearrangeDirection;
  index: number;
  type?: GalleryItemType;
}

export default DragType;
