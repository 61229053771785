import { Check } from '@air/next-icons';
import { tailwindVariants, type VariantProps } from '@air/tailwind-variants';
import * as RadixContextMenu from '@radix-ui/react-context-menu';
import classNames from 'classnames';
import { type ReactNode } from 'react';

import { contextMenuItem, ContextMenuItemVariant } from './ContextMenuItem';

export const contextMenuRadioItem = tailwindVariants({
  extend: contextMenuItem,
  base: 'justify-between',
});

export type ContextMenuRadioItemVariant = VariantProps<typeof contextMenuRadioItem>;

export type ContextMenuRadioItemProps = Omit<RadixContextMenu.ContextMenuRadioItemProps, 'prefix'> &
  Omit<ContextMenuItemVariant, 'description'> & {
    description?: ReactNode;
    label?: ReactNode;
    prefix?: ReactNode;
  };

export const ContextMenuRadioItem = ({
  children,
  className,
  description,
  label,
  prefix,
  ...restOfProps
}: ContextMenuRadioItemProps) => {
  const hasDescription = !!description;

  return (
    <RadixContextMenu.RadioItem className={contextMenuRadioItem({ className })} {...restOfProps}>
      {!!children ? (
        children
      ) : (
        <>
          {!!prefix ? (
            <div className={classNames('mr-2 shrink-0', hasDescription ? 'mt-0.5' : 'mt-0')}>{prefix}</div>
          ) : null}

          <div className="flex min-w-0 grow flex-col">
            <span className="truncate text-14">{label}</span>
            {hasDescription ? <span className="text-12 text-grey-8">{description}</span> : null}
          </div>
        </>
      )}

      <RadixContextMenu.ItemIndicator className={classNames('ml-2 shrink-0', hasDescription ? 'mt-0.5' : 'mt-0')}>
        <Check className="h-4 w-4" />
      </RadixContextMenu.ItemIndicator>
    </RadixContextMenu.RadioItem>
  );
};
