import { TriangleDown as TriangleDownIcon } from '@air/next-icons';
import { tailwindMerge, tailwindVariants, type VariantProps } from '@air/tailwind-variants';
import classNames from 'classnames';
import { useCombobox, useMultipleSelection, UseMultipleSelectionStateChange } from 'downshift';
import { constant, isEqual, noop } from 'lodash';
import { matchSorter } from 'match-sorter';
import maxSize from 'popper-max-size-modifier';
import {
  CSSProperties,
  KeyboardEvent,
  memo,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePopper } from 'react-popper';
import { useDebouncedCallback } from 'use-debounce';

import { InputClearButton } from '~/components/InputClearButton';
import { SelectChipOptionContainer } from '~/components/Select/components/SelectChipOptionContainer';
import { applyMaxSize, sameWidth } from '~/components/Select/popperModifiers';
import { SELECT_OPTION, SELECTED_CHIP } from '~/components/Select/shared/testIDs';
import { CLEAR_SELECTED_VALUES_BUTTON } from '~/constants/testIDs';

import { SelectChip } from './components/SelectChip';
import { SelectCreateOption } from './components/SelectCreateOption';
import { SelectEmptyOption } from './components/SelectEmptyOption';
import { SelectLoadingOption } from './components/SelectLoadingOption';
import { DefaultChipType } from './shared/types';

function alphabeticallySortedFilterFunc<T extends DefaultChipType>(
  items: T[],
  inputValue: string,
  selectedOptions: T[],
) {
  const matchedOptions = matchSorter(items, inputValue, { keys: ['label'] });
  const selectedValues = selectedOptions.map((option) => option.value);
  return matchedOptions.filter((option) => !selectedValues.includes(option.value));
}

// use to avoid alphabatising the initial list/results
export function orderedSortedFilterFunc<T extends DefaultChipType>(
  items: T[],
  inputValue: string,
  selectedOptions: T[],
) {
  const matchedOptions = matchSorter(items, inputValue, {
    baseSort: (a, b) => (a.index < b.index ? -1 : 1),
    keys: ['label'],
  });
  const selectedValues = selectedOptions.map((option) => option.value);
  return matchedOptions.filter((option) => !selectedValues.includes(option.value));
}

export interface OnSelectionChangeArgs<T extends DefaultChipType = DefaultChipType> {
  chips: T[];
  addedChips: T[];
  removedChips: T[];
}

export type ChipRenderer<T extends DefaultChipType> = (
  chip: T,
  onDeselect: (deselected: T) => void,
  meta?: { isOpen?: boolean },
) => ReactNode;

export type ListItemRenderer<T extends DefaultChipType> = (chip: T) => ReactNode;

export const select = tailwindVariants({
  base: "relative flex rounded [&>[data-popper-escaped='true']]:hidden [&>[data-popper-reference-hidden='true']]:hidden",
  variants: {
    size: {
      large: 'min-h-[40px]',
      'extra-large': 'min-h-[48px]',
    },
  },
  defaultVariants: {
    size: 'large',
  },
});

export const inputSelect = tailwindVariants({
  base: ' m-0.5 rounded border-none bg-transparent p-0 font-medium text-grey-11 outline-none ring-0 focus:border-none focus:ring-0',
  variants: {
    size: {
      large: 'text-12',
      'extra-large': 'text-14',
    },
  },
  defaultVariants: {
    size: 'large',
  },
});

export const placeholderSelect = tailwindVariants({
  base: 'm-0.5 px-0.5 text-grey-7',
  variants: {
    size: {
      large: 'text-12',
      'extra-large': 'text-14',
    },
  },
  defaultVariants: {
    size: 'large',
  },
});

export type SelectVariants = VariantProps<typeof select>;

export interface SelectProps<T extends DefaultChipType = DefaultChipType> extends SelectVariants {
  alwaysShowClearItemButton?: boolean;
  /**
   * opens on render
   */
  autoFocus?: boolean;
  /**
   * If `error` is truthy, it will stop the creation of a new option
   */
  beforeCreate?: (input: T) => { error?: string } | undefined;
  /**
   * Function to render a custom chip.
   */
  chipRenderer?: ChipRenderer<T>;
  className?: string;
  /**
   * Close dropdown menu when option is selected
   */
  closeMenuOnSelect?: boolean;
  /**
   * Verb used when typing new options. Defaults to `Create`
   */
  createVerb?: string;
  'data-testid'?: string;
  /**
   * Whether or not the input should render as disabled.
   */
  disabled?: boolean;
  /**
   * Function used to both order the dropdown list,
   * and filter results from the searchable input
   */
  filterFunc?: (items: T[], inputValue: string, selectedOptions: T[]) => T[];
  /**
   * overlay takes the whole screen so click outside of dropdown hides it without any events passed down
   */
  hasDropdownOverlay?: boolean;
  /**
   * Will add a red box shadow around the input to indicate that there is an error within it.
   * Error messages should be handled outside of the component.
   */
  hasError?: boolean;
  /**
   * We don't always want to show the X
   */
  hideClearWhenNotFocused?: boolean;
  /**
   * Class names for the container surrounding the input.
   */
  inputContainerClassName?: string;
  /**
   * True if you want a clear button to show on the right side of the input
   */
  isClearable?: boolean;
  /**
   * True if you are loading a new set of options
   */
  isLoading?: boolean;
  /**
   * True if the component should render an input to search through its list of items.
   * IMPORTANT: If this is false, and onInputChange is defined, onInputChange will not be called
   * because theres no input to change.
   */
  isSearchable?: boolean;
  /**
   * True if only 1 option should be able to be selected at a time
   */
  isSingleSelect?: boolean;
  /**
   * Will keep the dropdown position stickied to the input.
   */
  isSticky?: boolean;
  /**
   * Function to render custom list items.
   */
  listItemRenderer?: ListItemRenderer<T>;
  /**
   * A value to set as the max height for the dropdown.
   */
  maxDropdownHeight?: number;
  /**
   * Text rendered when theres no options available.
   */
  noOptionsText?: string;
  /**
   * Click function for individual chips
   */
  onChipClick?: (chip: T) => void;
  /**
   * Function called when an item is created.
   */
  onCreate?: (label: string) => void;
  /**
   * Function that allows to filter out incorrect input while adding a new option, e.g. if we want to accept only correct emails
   */
  createOptionFilter?: (input: string) => boolean;
  /**
   * Function called when the input changes.
   */
  onInputChange?: (search: string) => void;
  /**
   * triggers whenever the options are opened. Useful for async loading options.
   */
  onOptionsOpen?: () => void;
  /**
   * Intended for use when the options list is closed. Be aware however that this effect will
   * fire frequently, including on mount.
   */
  onOptionsClose?: () => void;
  /**
   * Function called when an item is selected or deselected
   */
  onSelectionChange: (args: OnSelectionChangeArgs<T>) => void;
  /**
   * The options to render in the select list.
   */
  options: T[];
  /**
   * Placeholder for the button.
   */
  placeholder?: string;
  /**
   * Defaults to `true`
   */
  pressEnterToAdd?: boolean;
  /**
   * True if the combobox should have a border like an input
   */
  renderAsInput?: boolean;
  /**
   * The options to render next to the input.
   */
  selectedOptions: T[];
  /**
   * shows the right adorned triangle
   */
  showTriangle?: boolean;
}

const _Select = <T extends DefaultChipType>({
  alwaysShowClearItemButton,
  autoFocus,
  beforeCreate = constant(undefined),
  chipRenderer,
  className,
  closeMenuOnSelect,
  createVerb,
  'data-testid': testId,
  disabled,
  filterFunc = alphabeticallySortedFilterFunc,
  hasDropdownOverlay,
  hasError,
  hideClearWhenNotFocused,
  inputContainerClassName,
  isLoading,
  isSearchable,
  isSingleSelect,
  isSticky = false,
  isClearable,
  listItemRenderer,
  maxDropdownHeight,
  noOptionsText,
  onCreate,
  onInputChange,
  onOptionsOpen,
  onOptionsClose,
  onSelectionChange,
  options,
  placeholder = '',
  pressEnterToAdd = true,
  renderAsInput,
  selectedOptions = [],
  showTriangle = false,
  size = 'large',
  createOptionFilter = constant(true),
}: SelectProps<T>) => {
  const [isCreating, setIsCreating] = useState(false);
  const [selectedItems, setSelectedItemsState] = useState(selectedOptions);
  const [inputItems, setInputItems] = useState(filterFunc(options, '', selectedOptions));
  const comboBoxRef = useRef(null);
  const popoverRef = useRef(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const labelRef = useRef<HTMLLabelElement | null>(null);
  const inputValueRef = useRef<string>('');
  const selectedItemsRef = useRef<T[]>(selectedOptions);
  const optionsRef = useRef<T[]>(options);

  const onOptionsOpenRef = useRef(onOptionsOpen);
  onOptionsOpenRef.current = onOptionsOpen;

  const onOptionsCloseRef = useRef(onOptionsClose);
  onOptionsCloseRef.current = onOptionsClose;

  const { styles, attributes, forceUpdate } = usePopper(comboBoxRef.current, popoverRef.current, {
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: [
      maxSize,
      applyMaxSize,
      sameWidth,
      {
        name: 'eventListeners',
        options: {
          scroll: isSticky,
          resize: false,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const [debouncedUpdatePopper] = useDebouncedCallback(() => {
    if (forceUpdate) forceUpdate();
  }, 200);

  const [selectedOptionValues, selectedOptionLabels] = useMemo(() => {
    return [selectedItems.map((option) => option.value), selectedItems.map((option) => option.label)];
  }, [selectedItems]);

  // This is called whenever downshift changes its internal selection state. We pass those changes
  // up to the parent component.
  const onSelectedItemsChange = useCallback(
    ({ selectedItems: chips }: UseMultipleSelectionStateChange<T>) => {
      if (!!inputRef.current && isSearchable && !closeMenuOnSelect) {
        inputRef.current.focus();
      }
      if (chips) {
        setSelectedItemsState(chips);

        const filteredItems = filterFunc(optionsRef.current, inputValueRef.current, chips);

        setInputItems(filteredItems);

        const previouslySelected = selectedItemsRef.current;
        selectedItemsRef.current = chips;

        let addedChips: T[] = [];
        let removedChips: T[] = [];

        if (isSingleSelect && chips.length === previouslySelected.length) {
          addedChips = chips;
          removedChips = previouslySelected;
        } else if (chips.length > previouslySelected.length) {
          const previousChipValues = previouslySelected.map((chip) => chip.value);
          addedChips = chips.filter((chip) => !previousChipValues.includes(chip.value));
        } else if (previouslySelected.length > chips.length) {
          const chipValues = chips.map((chip) => chip.value);
          removedChips = previouslySelected.filter((chip) => !chipValues.includes(chip.value));
        }
        onSelectionChange?.({ chips, addedChips, removedChips });
      }
    },
    [closeMenuOnSelect, filterFunc, isSearchable, isSingleSelect, onSelectionChange],
  );

  const { getDropdownProps, addSelectedItem, removeSelectedItem, setSelectedItems, reset } = useMultipleSelection({
    selectedItems,
    onSelectedItemsChange,
    itemToString: (item) => item.value,
  });

  const onClear = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (!isClearable) return;
      e.preventDefault();
      e.stopPropagation();
      reset();
    },
    [isClearable, reset],
  );

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    openMenu,
    getLabelProps,
    selectItem,
    setInputValue,
    inputValue,
    highlightedIndex,
    closeMenu,
  } = useCombobox<T | null>({
    initialIsOpen: autoFocus,
    selectedItem: null,
    items: inputItems,
    onInputValueChange: ({ inputValue: _inputValue }) => {
      const normalizedInputVal = _inputValue || '';

      if (!!onInputChange && inputValueRef.current !== normalizedInputVal) {
        onInputChange(normalizedInputVal);
      }

      const filteredItems = filterFunc(options, normalizedInputVal, selectedItemsRef.current);

      if (labelRef.current) {
        labelRef.current.dataset.value = normalizedInputVal;
      }

      debouncedUpdatePopper();

      setInputItems(filteredItems);

      inputValueRef.current = normalizedInputVal;
    },
    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        //TODO: 'changes' will include isOpen: false here, causing the menu to close if the input is blurred.
        // This is desired if you're clicking outside the element, but if you tab to the onClear button it will:
        // 1) close the menu
        // 2) reopen the menu
        // 3) return focus to the input.
        // Effectively preventing tabing to the clear button. However, if you return isOpen: true here the menu wont
        // close when you click outside the component. We need to find a way to allow tabbing within the input while
        // preserving the click outside functionality.
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '',
          };
        case useCombobox.stateChangeTypes.FunctionOpenMenu: {
          return {
            ...changes,
            isOpen: disabled ? false : changes.isOpen,
          };
        }
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
            inputValue: '',
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            if (isSingleSelect) {
              setSelectedItems([selectedItem]);
            } else if (selectedOptionValues.includes(selectedItem.value)) {
              removeSelectedItem(selectedItem);
            } else {
              addSelectedItem(selectedItem);
            }

            selectItem(null);
            if (closeMenuOnSelect) {
              closeMenu();
            }
          }
          break;
        default:
          break;
      }
    },
  });

  // Focus the input when the list is opened, and re-evalute the popper position.
  useEffect(() => {
    if (isOpen) {
      onOptionsOpenRef.current?.();
      if (forceUpdate) forceUpdate();
      if (!!inputRef.current && isSearchable) {
        inputRef.current.focus();
      }
    } else {
      onOptionsCloseRef.current?.();
    }
  }, [isOpen, forceUpdate, isSearchable]);

  // If the input value changes, and it would necessitate the create button showing, show the create button.
  // Else, hide it.
  useEffect(() => {
    if (!onCreate) return;

    if (inputValue.length > 0) {
      const hasChipAsOption = inputItems.some((item) => item.label.toLowerCase() === inputValue.toLowerCase());
      const hasChipSelected = selectedOptionLabels.includes(inputValue);
      const isInputValid = createOptionFilter(inputValue);
      if (isInputValid) {
        if (hasChipAsOption || hasChipSelected) {
          setIsCreating(false);
        } else {
          setIsCreating(true);
        }
      } else {
        setIsCreating(false);
      }
    } else {
      setIsCreating(false);
    }
  }, [createOptionFilter, inputItems, inputValue, onCreate, selectedOptionLabels]);

  // If new options are passed in, update the multi-selects internal state if theyre different.
  useEffect(() => {
    if (!isEqual(options, optionsRef.current)) {
      optionsRef.current = options;
      const filteredItems = filterFunc(options, inputValueRef.current, selectedItemsRef.current);
      setInputItems(filteredItems);
    }
  }, [filterFunc, options]);

  // If the selectedOptions prop changes, make sure its actually changed before updating
  // this internal state.
  useEffect(() => {
    if (!isEqual(selectedOptions, selectedItemsRef.current)) {
      selectedItemsRef.current = selectedOptions;
      setSelectedItemsState(selectedOptions);
      const filteredItems = filterFunc(optionsRef.current, inputValueRef.current, selectedOptions);
      setInputItems(filteredItems);
    }
  }, [filterFunc, selectedOptions]);

  // Adjust the popper positioning if the selected items changes
  // incase they've overflowed to a new row and the dropdown needs to reposition
  useEffect(() => {
    if (selectedItems && forceUpdate) {
      forceUpdate();
    }
  }, [selectedItems, forceUpdate]);

  // Add a new item when create is clicked
  const onClickCreate = useCallback(() => {
    if (!inputValue || !onCreate) return;
    const newChip = { label: inputValue, value: inputValue } as T;

    const { error } = beforeCreate(newChip) || { error: undefined };
    if (error) {
      return;
    }

    if (isSingleSelect) {
      setSelectedItems([newChip]);
      setSelectedItemsState([newChip]);
    } else {
      selectItem(newChip);
      setSelectedItemsState([...selectedItems, newChip]);
    }

    selectItem(null);

    selectedItemsRef.current = [...selectedItems, newChip];
    onCreate(inputValue);
    if (closeMenuOnSelect) {
      closeMenu();
      setTimeout(() => inputRef.current?.blur());
    }

    if (inputRef.current && isSearchable) inputRef.current.focus();
  }, [
    beforeCreate,
    closeMenu,
    closeMenuOnSelect,
    inputValue,
    isSearchable,
    isSingleSelect,
    onCreate,
    selectItem,
    selectedItems,
    setSelectedItems,
  ]);

  // Memoize popper styles so we're no repainting the div they're passed to every re-render.
  const popperStyles = useMemo<CSSProperties>(() => {
    const maxStylesHeight = styles.popper.maxHeight ? parseInt(styles.popper.maxHeight as string) : null;

    return {
      ...styles.popper,
      borderRadius: 4,
      maxHeight: maxDropdownHeight && maxStylesHeight ? Math.min(maxStylesHeight, maxDropdownHeight) : 'unset',
      zIndex: 2,
      overflow: 'auto',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.25)',
    };
  }, [maxDropdownHeight, styles.popper]);

  const listItems = useMemo(() => {
    return inputItems.map((item, index) => {
      // We dont spread this because it attaches a "mousemove" prop that hinders performance,
      // and isnt used by the component.
      const itemProps = getItemProps({ item, index });
      return (
        <button
          className={classNames('w-full items-start text-start', { isActive: highlightedIndex === index })}
          data-testid={`${SELECT_OPTION}-${item.label}`}
          key={`${item.label}${index}`}
          onClick={itemProps.onClick}
          id={itemProps.id}
          ref={itemProps.ref}
          role={itemProps.role}
          type="button"
        >
          {listItemRenderer ? (
            listItemRenderer(item)
          ) : (
            <SelectChipOptionContainer>
              <SelectChip chip={item} size={size} />
            </SelectChipOptionContainer>
          )}
        </button>
      );
    });
  }, [inputItems, getItemProps, highlightedIndex, listItemRenderer, size]);

  const inputProps = getInputProps({
    ref: inputRef,
    size: 1,
    tabIndex: 0,
  });

  // The chips to render next to the input.
  const selectedChips = useMemo(() => {
    return selectedItems.map((chip, index) => {
      if (chipRenderer)
        return (
          <div {...inputProps} key={`selected-chip-${chip.value}-${index}`}>
            {chipRenderer(chip, removeSelectedItem, { isOpen })}
          </div>
        );

      return (
        <SelectChip<T>
          data-testid={SELECTED_CHIP}
          chip={chip}
          size={size}
          key={`selected-chip-${chip.value}-${index}`}
          onDeselect={alwaysShowClearItemButton || isOpen ? removeSelectedItem : undefined}
        />
      );
    });
  }, [selectedItems, chipRenderer, inputProps, removeSelectedItem, isOpen, size, alwaysShowClearItemButton]);

  const showClearFieldButton = useMemo(() => {
    if (!isClearable) return false;
    if (hideClearWhenNotFocused && !isOpen) return false;
    return isClearable && !!selectedChips.length && !disabled;
  }, [disabled, hideClearWhenNotFocused, isClearable, isOpen, selectedChips]);

  const selectContent = useMemo(() => {
    return (
      <div
        className={tailwindMerge(
          'relative flex w-full flex-wrap items-center justify-start rounded bg-transparent p-1.5 transition-colors',
          inputContainerClassName,
          disabled ? 'pointer-events-none' : '',
          isOpen ? 'ring-2 ring-blue-9' : hasError ? 'ring-1 ring-red-9' : 'ring-1 ring-grey-5 hover:ring-grey-7',
          isOpen && hasError ? 'ring-2 ring-red-9' : '',
          renderAsInput && !isOpen ? 'ring-1 ring-grey-5' : '',
          renderAsInput && !hasError && !isOpen ? 'hover:ring-1 hover:ring-grey-7' : '',
          !renderAsInput && !isOpen && 'hover:bg-grey-2',
        )}
        {...getComboboxProps(
          getDropdownProps({
            onClick: isOpen ? noop : openMenu,
            onFocus: isOpen ? noop : openMenu,
            ref: comboBoxRef,
            tabIndex: -1,
          }),
        )}
      >
        {selectedChips.length || isOpen ? (
          selectedChips
        ) : (
          <p className={placeholderSelect({ size, className: 'placeholder' })}>{placeholder}</p>
        )}
        {/* Auto-sizing input based on: https://css-tricks.com/auto-growing-inputs-textareas/#other-ideas */}
        <label
          className={classNames('auto-width', {
            isHidden: isSingleSelect ? !isSearchable && !!selectedChips.length : !isSearchable,
          })}
          {...getLabelProps({ ref: labelRef })}
        >
          {isOpen && isSearchable && (
            <input
              className={inputSelect({ size, className: showClearFieldButton ? 'pr-6' : '' })}
              data-testid="SELECT_INPUT_FIELD"
              {...inputProps}
              onKeyDown={(e: KeyboardEvent) => {
                inputProps.onKeyDown?.(e);
                if (pressEnterToAdd && e.key === 'Enter') {
                  if (isCreating && onCreate) {
                    onClickCreate();
                    e.preventDefault();
                    e.stopPropagation();
                  }
                  if (!isCreating) {
                    const chip = inputItems.find((item) => item.label.toLowerCase() === inputValue.toLowerCase());
                    if (!chip) return;

                    setSelectedItems([...selectedItems, chip]);
                    setInputValue('');
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }
              }}
            />
          )}
        </label>
      </div>
    );
  }, [
    disabled,
    getComboboxProps,
    getDropdownProps,
    getLabelProps,
    hasError,
    inputContainerClassName,
    inputItems,
    inputProps,
    inputValue,
    isCreating,
    isOpen,
    isSearchable,
    isSingleSelect,
    onClickCreate,
    onCreate,
    openMenu,
    placeholder,
    pressEnterToAdd,
    renderAsInput,
    selectedChips,
    selectedItems,
    setInputValue,
    setSelectedItems,
    showClearFieldButton,
    size,
  ]);

  return (
    <div data-testid={testId} className={select({ size, className })}>
      {/* This must be rendered first, otherwise it covers selected chips and makes it impossible to remove them */}
      {isOpen && hasDropdownOverlay && <div className="fixed inset-0 bg-transparent" />}
      {/**
       * If it's open, we don't want a wrapping button so we don't have buttons nested in buttons
       */}
      {isOpen ? (
        <div className="flex flex-1">{selectContent}</div>
      ) : (
        <button className="flex flex-1 font-normal" disabled={disabled} data-testid={`${testId}_TRIGGER`} type="button">
          {selectContent}
        </button>
      )}
      <div style={popperStyles} {...attributes.popper} {...getMenuProps({ ref: popoverRef })}>
        {isOpen && (
          <ul className="w-full list-none overflow-auto rounded bg-grey-1 p-3">
            {isLoading ? <SelectLoadingOption /> : listItems}
            {(!isCreating || !inputValue) && !isLoading && !inputItems.length && (
              <SelectEmptyOption noOptionsText={noOptionsText} />
            )}

            {isCreating && !!inputValue && !!onCreate && (
              <SelectCreateOption
                onClick={onClickCreate}
                value={inputValue}
                hideSpacer={!inputItems.length && !isLoading}
                createVerb={createVerb}
              />
            )}
          </ul>
        )}
      </div>
      <div className="absolute inset-y-0 right-2.5 z-1 flex items-center">
        {showClearFieldButton && (
          <InputClearButton data-testid={CLEAR_SELECTED_VALUES_BUTTON} isVisible isFlex onClick={onClear} />
        )}
        {showTriangle && <TriangleDownIcon className="size-4 text-grey-10" />}
      </div>
    </div>
  );
};

export const Select = memo(_Select) as typeof _Select;
