import { Close } from '@air/next-icons';
import { IconButton, IconButtonProps } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';

export interface PaneButtonProps {
  onClick: IconButtonProps['onClick'];
  label: string;
  Icon: typeof Close;
  testId?: string;
}

export const PaneButton = ({ onClick, label, Icon, testId }: PaneButtonProps) => {
  return (
    <Tooltip label={label} sideOffset={4} side="top">
      <IconButton data-testid={testId} appearance="ghost" color="grey" onClick={onClick} size="small" label={label}>
        <Icon className="size-4" />
      </IconButton>
    </Tooltip>
  );
};
