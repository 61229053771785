import { Air } from '@air/next-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Image from 'next/legacy/image';
import ReactDOM from 'react-dom';

import CatDoingThings from '~/assets/cat-doing-things.svg';
import { MAINTENANCE_MODE_CONTAINER } from '~/constants/testIDs';

interface MaintenanceModeProps {
  children: JSX.Element;
}

export const MaintenanceMode = ({ children }: MaintenanceModeProps) => {
  const { maintenanceModeJson } = useFlags();

  if (maintenanceModeJson?.maintenanceMessage) {
    return ReactDOM.createPortal(
      <div
        className="fixed left-0 top-0 z-10 flex size-full flex-col items-center bg-white px-8"
        data-testid={MAINTENANCE_MODE_CONTAINER}
      >
        <Air className="my-2 w-[105px] text-blue-9" />
        <Image alt="A cat doing things" src={CatDoingThings} height={295} width={496} />
        <div className="max-w-screen-sm">
          <p className="mb-4 text-center font-gelica text-40 font-semibold text-grey-12">
            Air is currently unavailable
          </p>
          <div className="text-center text-20 text-grey-10">{maintenanceModeJson.maintenanceMessage}</div>
        </div>
      </div>,
      document.body,
    );
  }

  return children;
};
